import React from 'react';
import PricingCard from '../shared/PricingCard';
import { Tooltip as ReactTooltip } from 'react-tooltip'

const Pricing = () => {
  const getReviewedFeatures = [
    'Reviewing each document and information being shared',
    'Prepare you for the appointment',
    'ONE APPOINTMENT ONLY',
    'Provide the current trends with the targeted countries',
    'Sharpen and improve the application'
  ];

  const azPrepFeatures = [
    `Help you prepare all required documents, including those in the checklist that aren't ready yet`,
    'Prepare complete itinerary for your trip',
    'Dedicated 3hrs of meeting(broken into 30m-1hr slots)',
    'Unlimited emails and telegram support',
    'Personalized trip recommendation',
    'Europe/Canada travel hacks',
    'Priortity Appointment booking',
    'Airbnb, flights, card recommendations included'
  ];

  return (
    <div className="flex flex-col items-center bg-white p-4 md:p-10">
      <h1 className="text-black text-3xl md:text-5xl font-bold  mb-10">Pricing</h1>
      <div className="flex flex-col md:flex-row justify-center space-y-5 md:space-y-0 md:space-x-5">
      <div className="flex-1 flex">
          <PricingCard title="Get Reviewed" subtitle="(recommended for experienced folks)" features={getReviewedFeatures} price="1000" />
        </div>
        <div className="flex-1 flex">
          <PricingCard title="A-Z prep" subtitle="(new-bie or first timers)" features={azPrepFeatures} price="2000" />
        </div>
      </div>
      <div className="mt-7 text-center">
        <h1 className="text-black md:text-2xl font-semibold">
          <span>Countries suported: </span>
          <span data-tooltip-id='Schengen' className='md:text-4xl font-bold'>🇪🇺</span>
          <ReactTooltip id='Schengen' type='success' effect='float'>
            <span className='custom-tooltip'>Schengen</span>
          </ReactTooltip>
          <span data-tooltip-id="UK" className='md:text-4xl font-bold'> 🇬🇧</span>
          <ReactTooltip id='UK' type='success' effect='float'>
          <span className='custom-tooltip'>United Kingdom</span>
          </ReactTooltip>
          <span data-tooltip-id="Canada" className='md:text-4xl font-bold'> 🇨🇦</span>
          <ReactTooltip id='Canada' type='success' effect='float'>
          <span className='custom-tooltip'>Canada</span>
          </ReactTooltip>
          <span data-tooltip-id="Ireland" className='md:text-4xl font-bold'> 🇮🇪</span>
          <ReactTooltip id='Ireland' type='success' effect='float'>
          <span className='custom-tooltip'>Ireland</span>
          </ReactTooltip>
        </h1>
        <div className="mt-5 text-center">
        <h1 className="text-black md:text-2xl font-semibold">
          <span>Soon to be supported: </span>
          <span data-tooltip-id="Japan" className='md:text-4xl font-bold'> 🇯🇵</span>
          <ReactTooltip id='Japan' type='success' effect='float'>
          <span className='custom-tooltip'>Japan</span>
          </ReactTooltip>

          <span data-tooltip-id="USA" className='md:text-4xl font-bold'> 🇺🇸</span>
          <ReactTooltip id='USA' type='success' effect='float'>
          <span className='custom-tooltip'>United States</span>
          </ReactTooltip>

          <span data-tooltip-id="Australia" className='md:text-4xl font-bold'> 🇦🇺</span>
          <ReactTooltip id='Australia' type='success' effect='float'>
          <span className='custom-tooltip'>Australia</span>
          </ReactTooltip>

          <span data-tooltip-id="NewZealand" className='md:text-4xl font-bold'> 🇳🇿</span>
          <ReactTooltip id='NewZealand' type='success' effect='float'>
          <span className='custom-tooltip'>New Zealand</span>
          </ReactTooltip>
        </h1>
      </div>
      </div>
      
    </div>
  );
};

export default Pricing;
