import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import axios from 'axios';
import SideBar from '../shared/SideBar';
import config from '../config';

const Dashboard = () => {
  const navigate = useNavigate();
  const [profile, setProfile] = useState({ name: '' });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        if (!token) {
          navigate('/login');
          return;
        }
        const response = await axios.get(`${config.apiBaseUrl}/api/users/profile`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        
        setProfile(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching profile data:', error);
        if (error.response && (error.response.status === 401 || error.response.status === 404)) {
          console.log("Unauthorized access or user not found");
          localStorage.removeItem('accessToken');
          navigate('/login');
        }
        setLoading(false);
      }
    };

    fetchProfile();
  }, [navigate]);

  const LoadingSpinner = () => (
    <div 
      className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden flex flex-col items-center justify-center"
      style={{background: "linear-gradient(rgba(0, 0, 128, 1), rgba(0, 0, 61, 1))"}}
    >
      <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
      <h2 className="text-center text-white text-xl font-semibold">Loading...</h2>
      <p className="w-1/3 text-center text-white">This may take a few seconds, we are loading your profile</p>
    </div>
  );

  const handleLogout = () => {
    localStorage.removeItem('accessToken');
  };

  return (
    <>
    {loading ? (
      <LoadingSpinner />
    ) : (
    <div className="min-h-screen flex flex-col lg:flex-row lg:p-12 text-white" style={{background: "linear-gradient(rgba(0, 0, 128, 1), rgba(0, 0, 61, 1))"}}>
      <SideBar handleLogout={handleLogout} />
      <div className="flex-grow p-6 lg:p-10 lg:pt-6 items-center justify-center">
        <div className="mt-0 mb-8">
          <h1 className="text-xl sm:text-3xl font-bold">Hello, {profile.name}</h1>
        </div>
        <Outlet />
      </div>
    </div>
    )}
    </>
  );
};

export default Dashboard;
