import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import config from "../config";
import Swal from "sweetalert2";
const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [isTokenValid, setIsTokenValid] = useState(false);

  const token = new URLSearchParams(location.search).get("token");

  const Toast = useRef(
    Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      },
    })
  );

  useEffect(() => {
    const validateToken = async () => {
      try {
        const response = await axios.post(`${config.apiBaseUrl}/checkToken`, {
          token,
        });
        if (response.status === 200) {
          setIsTokenValid(true);
        } else {
          setError("Invalid or expired token.");
          Toast.current.fire({
            icon: "error",
            title:
              response.data.message ||
              "Something went wrong. Please try again later.",
          });
          navigate("/login");
        }
      } catch (err) {
        setError("An error occurred while validating the token.");
        Toast.current.fire({
          icon: "error",
          title: "Something went wrong. Please try again later.",
        });
        navigate("/login");
      }
    };

    if (token) {
      validateToken();
    } else {
      setError("Token is missing.");
      navigate("/login");
    }
  }, [token, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    localStorage.removeItem("accessToken");
    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      Toast.current.fire({
        icon: "error",
        title: "Passwords do not match.",
      });
      return;
    }

    try {
      const response = await axios.post(`${config.apiBaseUrl}/reset`, {
        email,
        password,
        token,
      });
      if (response.status === 200) {
        Toast.current.fire({
          icon: "success",
          title: response.data.message,
        });
        navigate("/login");
      } else {
        Toast.current.fire({
          icon: "error",
          title: response.data.message,
        });
        navigate("/login");
      }
    } catch (err) {
      setError("Failed to reset password. Please try again.");
      Toast.current.fire({
        icon: "error",
        title: "Failed to reset password. Please try again.",
      });
    }
  };

  if (!isTokenValid) return null;

  return (
    <div
      className="flex flex-col items-center justify-center min-h-screen relative"
      style={{
        background: "linear-gradient(rgba(0, 0, 128, 1), rgba(0, 0, 61, 1))",
      }}
    >
      <div
        className="absolute inset-0 bg-cover bg-center opacity-50"
        style={{ opacity: 0.08 }}
      ></div>

      <div className="relative text-center">
        <h2 className="text-2xl font-bold text-white mb-6">Viszapp</h2>
        <h3 className="text-3xl font-semibold text-white mb-11">
          Reset Password
        </h3>
      </div>

      <div className="relative z-10 bg-white p-6 rounded-xl shadow-lg max-w-md w-full">
        {error && <p className="text-red-500 text-center mb-4">{error}</p>}

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-black mb-2" htmlFor="email">
              Email
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
              placeholder="Email"
              required
            />
          </div>

          <div>
            <label className="block text-black mb-2" htmlFor="password">
              New Password
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
              placeholder="New Password"
              required
            />
          </div>

          <div>
            <label className="block text-black mb-2" htmlFor="confirmPassword">
              Confirm Password
            </label>
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
              placeholder="Confirm Password"
              required
            />
          </div>

          <button
            type="submit"
            className="w-full bg-blue-700 text-white p-3 mt-4 rounded-md font-semibold hover:bg-blue-800 transition duration-200"
          >
            Reset Password
          </button>
        </form>

        <div className="text-center mt-6">
          <p className="text-black">
            Remembered your password?{" "}
            <a href="/login" className="text-blue-900">
              Log In
            </a>
          </p>
        </div>
        <div className="text-center mt-6">
          <p className="text-black">
            Back to{" "}
            <a href="/" className="text-blue-900">
              Home
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
